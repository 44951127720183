import "../styles.scss"
import "../../../WorkOrderDetails/react-datasheet.css"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
import { InverseModel as Comp, InverseModelProps } from "./child"
import {
  Alert,
  Drawer,
  Dropdown,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Descriptions,
  List as AntdList,
  Popconfirm,
} from "antd";

import { useCallback, useEffect, useRef, useState } from "react"
import { useQuery } from "src/utils/useQuery"
import useTranslate, { LanguageUnion } from "src/utils/useTranslate"
import { useHistory } from "react-router-dom"
import { CloseOutlined, DownOutlined, HistoryOutlined, LoadingOutlined, StarOutlined } from "@ant-design/icons"
import { messages } from "src/utils/hooks"
import { StyledButton } from "src/styled_components/StyledButton"
import { useForm } from "antd/es/form/Form"
import { addFavouritesInInverseRequest, getFavouritesListInInverseRequest } from "src/store/actions/inverseModel"
import { StyledCard } from "src/styled_components/StyledCard"
import { ScrollToTopInDrawer } from "src/components/Common"
import utc from 'dayjs/plugin/utc'
import dayjs from "dayjs"
import { useMemberName } from "src/utils/useMemberName"
dayjs.extend(utc)

const { Text } = Typography;
const { Option } = Select;

type P = {
  tab: string
  setUnsavedChanges: Function
  selectedObjective: any
  setExperimentsCurrent: any
  experimentsCurrent: any
  setSelectedObjective: any
}

export type ZeonCurrentPageInfo = {
  variation_id: number;
  version: number;
  currentPage: number;
}

export type Filter = {
  pageNum: number
  user_ids: string[]
  model_type: string[]
  status: string[]
  versions: string[]
}

export const InverseModelCatwise = ({
  tab,
  setUnsavedChanges,
  selectedObjective,
  setExperimentsCurrent,
  experimentsCurrent,
  setSelectedObjective,
}: P) => {
  let query: any = useQuery();
  let modelVersion = query?.get('version');
  const [t] = useTranslate();
  const { push } = useHistory();

  const [isMultistage, setIsMultistage] = useState<boolean>(false)

  const dispatch = useDispatch()

  const configs = useSelector((state: StoreState) => state.configs.features)


  const [tipVisible, setTipVisible] = useState(true);

  const [showAddFavoriteModal, setShowAddFavoriteModal] = useState({
    isModalVisible: false,
    data: null
  })
  const [showFavouritesDrawer, setShowFavouritesDrawer] = useState({
    isDrawerVisible: false,
    selectedData: null
  })


  const {
    configData,
    configStatus
  } = useSelector(
    (state: StoreState) => state.formulate
  )

  const ln: LanguageUnion = useSelector(
    (state: StoreState) => state.language.current
  )

  // When user refreshes with version in URL, this effect sets radio and dropdown properly
  useEffect(() => {
    setIsMultistage(
      configData?.find(
        (modelData: any) => modelData?.version === Number(modelVersion),
      )?.is_multistage || false,
    )
  }, [configData, isMultistage, modelVersion])

  const { statusMinmax, statusRange } = useSelector(
    (state: StoreState) => state.inverseModel,
  )
  const { expIdStatus } = useSelector((state: StoreState) => state.suggestedExp)


  useEffect(() => {
    if (!Boolean(modelVersion) && !!configData?.length) {
      push(
        `/ai-engine/predict/formulation_prediction?version=${configData.filter(
          ({ is_inverse }: any) => is_inverse,
        )?.[0].version
        }`,
      )
    }
  }, [configData, modelVersion, push])

  useEffect(() => {
    if (
      statusRange?.[0]?.status === AsyncStates.SUCCESS ||
      statusMinmax?.[0]?.status === AsyncStates.SUCCESS ||
      expIdStatus === AsyncStates.SUCCESS
    ) {
      const suggestedExpDocument = document.getElementById(
        "suggested-experiments",
      )
      suggestedExpDocument?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [expIdStatus, statusRange, statusMinmax])

  const handleAddToFavourites = () => {
    setShowAddFavoriteModal((prev) => ({
      ...prev,
      isModalVisible: true,
    }))
  }

  return (
    <Space size={"large"} direction="vertical" style={{ width: "100%" }}>
      {!!configData.filter((res: any) => res?.is_inverse).length &&
        // !Object.keys(inversePropertiesData?.data || {}).length &&
        !Boolean(modelVersion) &&
        tipVisible && (
          <Alert
            description={
              <Space style={{ paddingLeft: '7px' }}>
                <Typography.Text style={{ margin: 0 }}>{t("aiEngine.tip")}{":"}</Typography.Text>
                <Typography.Text style={{ margin: 0 }}>{t("aiEngine.noModelFound")}</Typography.Text>
              </Space>
            }
            style={{ border: 'none', padding: "16px" }}
            type="info"
            closable
            afterClose={() => {
              setTipVisible(false)
            }}
            closeIcon={
              <StyledButton
                size="small"
                style={{
                  color: "black",
                  outline: "none",
                }}
                type="link"
              >
                <CloseOutlined />
              </StyledButton>
            }
          />
        )}

      <Space
        direction="horizontal"
        style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}
        size={"middle"}
      >
        <div
          style={{ alignItems: "center", display: "flex", justifyContent: "space-between", gap: "0.5rem" }}
        >
          {
            !!configData.filter((res: any) => res?.is_inverse).length ? (
              <Space>
                {/* <Checkbox
                  checked={Boolean(modelVersion)}
                  value={Number(modelVersion)}
                  style={{ height: "100%" }}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      push("/ai-engine/predict/formulation_prediction")
                      dispatch(modelsConfigCleanup())
                    } else {
                      push(
                        `/ai-engine/predict/formulation_prediction?version=${configData.filter(
                          ({ is_inverse, is_multistage }: any) =>
                            is_inverse && isMultistage
                              ? is_multistage
                              : !is_multistage,
                        )?.[0].version
                        }`,
                      )
                    }
                  }}
                >
                  <Text strong>{t("aiEngine.useCustomModel")}</Text>
                </Checkbox> */}
              </Space>
            ) : <Space>
              <Spin
                indicator={<LoadingOutlined />}
                spinning={configStatus === AsyncStates.LOADING}
              />
              <Typography.Text>{configStatus !== AsyncStates.ERROR ? `${t("common.loading")} ${t("common.models")}` : `${messages[ln].internal_server_error}`}</Typography.Text>
            </Space>
          }
          {
            Boolean(modelVersion) && (
              <>
                <div style={{height:'2rem'}} >
                  <Radio.Group
                    value={isMultistage}
                    onChange={(e) => {
                      setIsMultistage(Boolean(e.target.value))
                      push(
                        `/ai-engine/predict/formulation_prediction?version=${configData.filter(
                          ({ is_inverse, is_multistage }: any) =>
                            is_inverse &&
                            (e.target.value ? is_multistage : !is_multistage),
                        )?.[0].version
                        }`,
                      )
                    }}
                  >
                    <Radio
                      value={false}
                      disabled={
                        !Boolean(
                          configData.filter(
                            (modelData: any) =>
                              modelData.is_inverse && !modelData.is_multistage,
                          ).length,
                        )
                      }
                    >
                      {t("aiEngine.singleStage")}
                    </Radio>
                    <Radio
                      value={true}
                      disabled={
                        !Boolean(
                          configData.filter(
                            (modelData: any) =>
                              modelData.is_inverse && modelData.is_multistage,
                          ).length,
                        )
                      }
                    >
                      {t("aiEngine.multiStage")}
                    </Radio>
                  </Radio.Group>
                </div>
                <Space style={{height:'2rem'}}>
                  <Text strong style={{ wordBreak: "normal" }}>
                    {t("aiEngine.selectModel")}:{" "}
                  </Text>
                  <Select
                    style={{ maxWidth: 250, minWidth: 250 }}
                    value={Number(modelVersion)}
                    showSearch
                    optionFilterProp="label"
                    placeholder={t("aiEngine.placeholder.selectModelVersion")}
                    onSelect={(e: any) => {
                      push(
                        `/ai-engine/predict/formulation_prediction?version=${e}`,
                      )
                    }}
                  >
                    {configData
                      .filter(
                        (modelData: any) =>
                          modelData.is_inverse &&
                          (isMultistage
                            ? modelData.is_multistage
                            : !modelData.is_multistage),
                      )
                      .map((modelData: any) => (
                        <Option key={modelData?.version} value={modelData?.version} label={`${modelData.objective} (${modelData.comments})`}>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.25rem",
                              justifyContent: "space-between",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Text
                              ellipsis={{ tooltip: true }}
                              style={{ margin: 0, maxWidth: "95%" }}
                            >
                              {`${modelData.objective} (${modelData.comments})`}
                            </Text>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Space>
              </>
            )
          }
        </div>
        <div>
          <Dropdown
            disabled={!Boolean(modelVersion) || configStatus === AsyncStates.LOADING || !configData.filter((res: any) => res?.is_inverse)?.length}
            placement="bottomRight"
            menu={{
              items: [
                {
                  label: `${t("common.addToFavourites")}`,
                  key: "1",
                  onClick: () => handleAddToFavourites(),
                  icon: <StarOutlined />,
                  style: { outline: "none" },
                },
                {
                  label: `${t("common.savedFavourites")}`,
                  key: "0",
                  onClick: () => {
                    setShowFavouritesDrawer({
                      isDrawerVisible: true,
                      selectedData: null
                    })
                    dispatch(getFavouritesListInInverseRequest({ model_version: modelVersion }))
                  },
                  icon: <HistoryOutlined />,
                  style: { outline: "none" },
                },
              ],
            }}
          >
            <StyledButton
              icon={<StarOutlined />}
              type="default"
              size="small"
              style={{ borderRadius: 5, outline: "none" }}
            >
              <Space>
                {t("common.Favourites")}
                <DownOutlined />
              </Space>
            </StyledButton>
          </Dropdown>
        </div>
      </Space>


      {Boolean(configs?.ai_engine_with_methods) ||
        Boolean(configs?.characterization_methods) ? (
        <ZeonComponent
          setExperimentCurrent={setExperimentsCurrent}
          setSelectedObjective={setSelectedObjective}
          setUnsavedChanges={setUnsavedChanges}
          tab={tab}
          showAddFavoriteModal={showAddFavoriteModal}
          setShowAddFavoriteModal={setShowAddFavoriteModal}
          showFavouritesDrawer={showFavouritesDrawer}
          setShowFavouritesDrawer={setShowFavouritesDrawer}
        />
      ) : (
        <GeneralComponent
          setExperimentCurrent={setExperimentsCurrent}
          setSelectedObjective={setSelectedObjective}
          setUnsavedChanges={setUnsavedChanges}
          tab={tab}
          showAddFavoriteModal={showAddFavoriteModal}
          setShowAddFavoriteModal={setShowAddFavoriteModal}
          showFavouritesDrawer={showFavouritesDrawer}
          setShowFavouritesDrawer={setShowFavouritesDrawer}
        />
      )}

      <InverseAddToFavoriitesModal
        showAddFavoriteModal={showAddFavoriteModal}
        setShowAddFavoriteModal={setShowAddFavoriteModal}
      />
      <InverseFavouriteListDrawer
        showFavouritesDrawer={showFavouritesDrawer}
        setShowFavouritesDrawer={setShowFavouritesDrawer}
      />
    </Space>
  )
}

const GeneralComponent = (props: InverseModelProps) => {
  return <Comp {...props} />
}

const ZeonComponent = (props: InverseModelProps) => {
  return <Comp {...props} />
}


const InverseAddToFavoriitesModal = ({ setShowAddFavoriteModal, showAddFavoriteModal }: any) => {
  const [t] = useTranslate()
  const [form] = useForm()
  const dispatch = useDispatch()

  const addFavouritesInInverseStatus = useSelector((state: StoreState) => state.inverseModel.addFavouritesInInverseStatus)
  // const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData)

  const submitForm = (values: any) => {

    // Commenting Validation for now

    // const numericalProperties = showAddFavoriteModal.data?.user_input_data?.numerical_properties
    // const stages = Object.keys(numericalProperties)
    // for (const stage of Object.keys(numericalProperties)) {
    //   const actualStageName = modelConfigData[0]?.all_stages?.[Number(stage) - 1];
    //   const categoricalRange = showAddFavoriteModal.data?.user_input_data?.categorical_range

    //   const combinedData = [
    //     ...numericalProperties[stage],
    //     ...categoricalRange[stage],
    //   ]

    //   const checkedProperties = combinedData.filter((res) => !!res.parameter)

    //   const isMultiStageModel = modelConfigData?.[0]?.is_multistage ?? false

    //   if (checkedProperties.length < 1 && stage === String(stages[stages.length - 1])) {
    //     return message.error(`${t("aiEngine.selectOneObjective")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`)
    //   }
    // }

    const favouritesData = {
      ...showAddFavoriteModal.data,
      ...values
    }

    dispatch(addFavouritesInInverseRequest(favouritesData))
  }

  const handleClose = useCallback(() => {
    form.resetFields()
    setShowAddFavoriteModal({
      isModalVisible: false,
      data: {}
    })
  }, [form, setShowAddFavoriteModal])

  useEffect(() => {
    if (addFavouritesInInverseStatus === AsyncStates.SUCCESS) {
      handleClose()
    }
  }, [addFavouritesInInverseStatus, handleClose])


  return <Modal
    open={showAddFavoriteModal.isModalVisible}
    onCancel={() => {
      handleClose()
    }}
    title={"Add to Favourites"}
    footer={null}
  >
    <Form onFinish={submitForm} layout="vertical" form={form}>
      <Form.Item
        name={"title"}
        rules={[{ required: true }]}
        label={t("common.title")}
      >
        <Input type="text" />
      </Form.Item>

      <Row justify="end">
        <Form.Item>
          <StyledButton
            htmlType="submit"
            type="primary"
            loading={addFavouritesInInverseStatus === AsyncStates.LOADING}
          >
            {t("common.addToFavourites")}
          </StyledButton>
        </Form.Item>
      </Row>
    </Form>
  </Modal>
}

const InverseFavouriteListDrawer = ({ showFavouritesDrawer, setShowFavouritesDrawer }: any) => {

  const drawerRef = useRef<HTMLDivElement>(null)

  const [t] = useTranslate()
  const { getName } = useMemberName()


  const handleDrawerClose = () => {
    setShowFavouritesDrawer((prev: any) => ({
      ...prev,
      isDrawerVisible: false
    }))
  }

  const favouritesListInverse = useSelector((state: StoreState) => state.inverseModel.favouritesListInverse)
  const favouritesListInverseStatus = useSelector((state: StoreState) => state.inverseModel.favouritesListInverseStatus)

  const applyFavourite = (item: any) => {
    setShowFavouritesDrawer((prev: any) => ({
      isDrawerVisible: false,
      selectedData: item
    }))
  }

  return <Drawer
    open={showFavouritesDrawer.isDrawerVisible}
    onClose={() => handleDrawerClose()}
    title={t("common.Favourites")}
    width={"40%"}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <AntdList
        loading={{
          spinning: favouritesListInverseStatus === AsyncStates.LOADING,
          indicator: <LoadingOutlined />
        }}
        pagination={false}
        size="small"
        grid={{ column: 1, gutter: 16 }}
        dataSource={favouritesListInverse}
        renderItem={(favouritesItem) => (
          <AntdList.Item>
            <StyledCard
              size="small"
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography.Title
                    ellipsis={{
                      tooltip: favouritesItem.title
                    }}
                    level={4}>
                    {favouritesItem.title}
                  </Typography.Title>
                </div>
              }
              extra={
                <Popconfirm
                  okText={t("common.ok")}
                  cancelText={t("common.cancel")}
                  title={`${t('common.apply')} ?`}
                  onConfirm={() => applyFavourite(favouritesItem)}
                >
                  <StyledButton
                    type="link"
                    size="middle"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {t('common.apply')}
                  </StyledButton>
                </Popconfirm>
              }
            >
              <Descriptions
                column={1}
                bordered
                size="small"
                style={{ background: "white" }}
              >
                <Descriptions.Item label={"Model Name"}>
                  <Typography.Text
                    style={{ maxWidth: 300 }}
                    ellipsis={{
                      tooltip: favouritesItem.model_name
                    }}>
                    {favouritesItem.model_name}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("aiEngine.user")}>
                  {getName(favouritesItem.user_id)}
                </Descriptions.Item>
                {favouritesItem.created && (
                  <Descriptions.Item label={t("common.createdOn")}>
                    {dayjs.utc(favouritesItem.created).format("MMMM Do YYYY, h:mm A") +
                      " (UTC)"}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </StyledCard>
          </AntdList.Item>
        )}
      />
      {favouritesListInverse.length > 5 && <ScrollToTopInDrawer sectionRef={drawerRef} />}
    </div>
  </Drawer>
}