import { Modal, Space, Steps, Typography } from 'antd';
import { NumericalOutputConstraints } from './numerical-output-constraints'
import { CategoricalOutputConstraints } from '../categorical-output-constraints'
import Constraints from './Constraints'
import { useCallback, useEffect, useState } from 'react'
import './input.scss'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { InverseConfigs } from '../InverseConfigs'
import { SettingOutlined } from '@ant-design/icons';
import { StyledButton } from 'src/styled_components/StyledButton';
import { antdTheme, AsyncStates } from 'src/constants';

const PayloadInput = ({
  modelData,
  addRow,
  disableCell,
  numericalObjectiveList,
  parameterListDropDown,
  setUnsavedChanges,
  numericalProperties,
  setRange,
  currentSelectedStage,
  isMultiStageModel,
  categoricalObjectiveList,
  categoricalRange,
  optionsDropDown,
  rangeParameterList,
  setRangeParameterList,
  catwiseRangeParameterList,
  setCatwiseRangeParameterList,
  categorialInputList,
  setCategorialInputList,
  activePredictionInputTab,
  setActivePredictionInputTab,
  selectedStages,
  catConstraintsList,
  setCatConstraintsList,
  setSelectAllIngredientsInCategory,
  selectAllIngredientsInCategory,
  exludeAllParameterConstraints,
  setExludeAllParameterConstraints,
  exludeAllCategoryConstraints,
  setExludeAllCategoryConstraints,
  isCrossCategorical,
  setIsCrossCategorical,
  setIsCrossCategoricalModalVisible,
  isCategoricalConstraintsUseDefault,
  setIsCategoricalConstraintsUseDefault,
  setInverseConfigurationData,
  inverseConfigurationData,
  validationsForSteps,
  configsOpenedViaPredictButton,
  generateExp,
  setConfigsOpenedViaPredictButton
}: any) => {
  const [t] = useTranslate()

  const [isCofigurationModalVisible, setIsCofigurationModalVisible] = useState(false)

  const onChange = useCallback((step: number) => {
    if (validationsForSteps()) {
      setActivePredictionInputTab((prev: any) => ({
        ...prev,
        [currentSelectedStage]: step
      }))
    }
  }, [currentSelectedStage, setActivePredictionInputTab, validationsForSteps])

  useEffect(() => {
    if (configsOpenedViaPredictButton) {
      setIsCofigurationModalVisible(true)
    }
  }, [configsOpenedViaPredictButton])

  const configs = useSelector((state: StoreState) => state.configs.features)

  // For the time being TODO : Remove after multistage objectives support
  useEffect(() => {
    if (currentSelectedStage !== selectedStages[1]) {
      if (activePredictionInputTab === 0 || !activePredictionInputTab?.[currentSelectedStage])
        setActivePredictionInputTab((prev: any) => ({
          ...prev,
          [currentSelectedStage]: 1
        }))
    }
  }, [
    activePredictionInputTab,
    currentSelectedStage,
    selectedStages,
    setActivePredictionInputTab,
  ])

  useEffect(() => {
    const collator = new Intl.Collator([], { numeric: true })

    const defaultConstraintsIngredients = Array.from(
      new Set(
        Object.values(modelData?.categories?.ingredients || {})
          ?.flatMap((i: any) => i)
          .sort(collator.compare),
      ),
    ).flatMap((category, catIndex) => [
      {
        constraint: "sum_of_category_quantity",
        type: "ingredients",
        category,
        min: null,
        max: null,
        catIndex,
      },
      {
        constraint: "count_of_category_items",
        type: "ingredients",
        category,
        min: null,
        max: null,
        catIndex,
      },
    ])
    const defaultConstraintsProcessing = Array.from(
      new Set(
        Object.values(modelData?.categories?.processing || {})?.flatMap(
          (i: any) => i,
        ),
      ),
    ).flatMap((category, catIndex) => [
      {
        constraint: "sum_of_category_quantity",
        type: "processing",
        category,
        min: null,
        max: null,
        catIndex,
      },
      {
        constraint: "count_of_category_items",
        type: "processing",
        category,
        min: null,
        max: null,
        catIndex,
      },
    ])
    const defaultCategoriesIngredients = (previousCategoriesData: any[]) => Array.from(
      new Set(
        Object.values(modelData?.categories?.ingredients || {})
          ?.flatMap((i: any) => i)
          .sort(collator.compare),
      ),
    ).map((category) => ({
      category,
      ...(category === "Reactants" || category === "Solvents"
        ? {
          exclude: false,
          include: true,
        }
        : {
          exclude: Boolean(configs.nestle_configs) ? true : exludeAllCategoryConstraints?.["ingredients"]?.includes(category) ?? false,
          include: previousCategoriesData?.find((prevCat: any) => prevCat.category === category)?.include ?? false,
        }),
      sum_of_category_quantity: { min: null, max: null },
      count_of_category_items: { min: null, max: null },
      item_constraints: {},
      parameter_constraints_categorical: [],
      type: "ingredients",
    }))
    const defaultCategoriesProcessing = Array.from(
      new Set(
        Object.values(modelData?.categories?.processing || {})
          ?.flatMap((i: any) => i)
          .sort(collator.compare),
      ),
    ).map((category) => ({
      category,
      exclude: false,
      include: true,
      sum_of_category_quantity: { min: null, max: null },
      count_of_category_items: { min: null, max: null },
      item_constraints: {},
      parameter_constraints_categorical: [],
      type: "processing",
    }))
    setCatConstraintsList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      if (!newState.hasOwnProperty(String(currentSelectedStage)) || newState?.[currentSelectedStage]?.length <= 0) {
        newState[currentSelectedStage] = [
          ...defaultConstraintsIngredients,
          ...defaultConstraintsProcessing,
        ]
      }
      return newState
    })
    setCatwiseRangeParameterList((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = [
        {
          parameter_type: "ingredients",
          categories: defaultCategoriesIngredients(newState?.[currentSelectedStage]?.[0]?.categories),
        },
        {
          parameter_type: "processing",
          categories: defaultCategoriesProcessing,
        },
      ]
      return newState
    })

    // Not including currentSelectedStage as modelData dependency covers it anyway and we don't want rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modelData,
    setCatwiseRangeParameterList,
    currentSelectedStage,
    configs.nestle_configs,
  ])

  const renderStepContent = useCallback(() => {
    if (activePredictionInputTab[currentSelectedStage] === 0) {
      return (
        <Space direction="vertical" size="large">
          {/* OUTPUT CONSTRAINTS */}
          <NumericalOutputConstraints
            addRow={addRow}
            disableCell={disableCell}
            numericalObjectiveList={numericalObjectiveList}
            parameterListDropDown={parameterListDropDown}
            setUnsavedChanges={setUnsavedChanges}
            numericalProperties={numericalProperties}
            setNumericalProperties={setRange}
            currentSelectedStage={currentSelectedStage}
            isMultiStage={isMultiStageModel}
          />

          {categoricalObjectiveList.length > 0 && (
            <CategoricalOutputConstraints
              addRow={addRow}
              categoricalObjectiveList={categoricalObjectiveList}
              categoricalRange={categoricalRange}
              optionsDropDown={optionsDropDown}
              parameterListDropDown={parameterListDropDown}
            />
          )}
        </Space>
      )

    }

    if (activePredictionInputTab[currentSelectedStage] === 1) {
      return (
        <Constraints
          parameterList={rangeParameterList}
          setParameterList={setRangeParameterList}
          catwiseParameterList={catwiseRangeParameterList}
          setCatwiseParameterList={setCatwiseRangeParameterList}
          modelData={modelData}
          setUnsavedChanges={setUnsavedChanges}
          categorialInputList={categorialInputList}
          setCategorialInputList={setCategorialInputList}
          range={numericalProperties}
          categoricalRange={categoricalRange}
          currentSelectedStage={currentSelectedStage}
          isMultiStage={isMultiStageModel}
          catConstraintsList={catConstraintsList}
          setCatConstraintsList={setCatConstraintsList}
          setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
          selectAllIngredientsInCategory={selectAllIngredientsInCategory}
          exludeAllParameterConstraints={exludeAllParameterConstraints}
          setExludeAllParameterConstraints={setExludeAllParameterConstraints}
          exludeAllCategoryConstraints={exludeAllCategoryConstraints}
          setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
          isCrossCategorical={isCrossCategorical}
          setIsCrossCategorical={setIsCrossCategorical}
          setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}
          isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
          setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
        />
      )
    }

  }, [activePredictionInputTab, addRow, catConstraintsList, categorialInputList, categoricalObjectiveList, categoricalRange, catwiseRangeParameterList, currentSelectedStage, disableCell, exludeAllCategoryConstraints, exludeAllParameterConstraints, isCategoricalConstraintsUseDefault, isCrossCategorical, isMultiStageModel, modelData, numericalObjectiveList, numericalProperties, optionsDropDown, parameterListDropDown, rangeParameterList, selectAllIngredientsInCategory, setCatConstraintsList, setCategorialInputList, setCatwiseRangeParameterList, setExludeAllCategoryConstraints, setExludeAllParameterConstraints, setIsCategoricalConstraintsUseDefault, setIsCrossCategorical, setIsCrossCategoricalModalVisible, setRange, setRangeParameterList, setSelectAllIngredientsInCategory, setUnsavedChanges])


  const defaultInverseConfigData = useSelector((state: StoreState) => state.inverseModel.inverseConfigData)

  const newInverseModel = useSelector(
    (state: StoreState) => state.newInverseModel
  )


  return (
    <div style={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    }} >
      <Space style={{
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center"
      }} >
        <Steps
          current={activePredictionInputTab[currentSelectedStage]}
          onChange={onChange}
          items={[
            {
              title: `${t("common.objectives")}`,
              style: { display: !(currentSelectedStage === selectedStages[1]) ? 'none' : "flex" }
            },
            {
              title: `${t("common.constraints")}`,
            },
          ]}
        />
        {currentSelectedStage === selectedStages[1] && <StyledButton
          icon={<SettingOutlined />}
          type="default"
          size="small"
          style={{ borderRadius: 5, outline: "none" }}
          onClick={() => setIsCofigurationModalVisible(true)}
        >
          {t("aiEngine.configurations")}
        </StyledButton>}
      </Space>
      {renderStepContent()}

      <Modal
        title={<Space direction='vertical' >
          <Typography.Title level={4}>
            {t("aiEngine.configurations")}
          </Typography.Title>
          <Typography.Text type={'secondary'} style={{
            fontSize: antdTheme.fontSizeSM
          }}>
            {t('aiEngine.inverseConfigs.note')}
          </Typography.Text>
        </Space>}
        open={isCofigurationModalVisible}
        onCancel={() => {
          setIsCofigurationModalVisible(false)
          if (configsOpenedViaPredictButton) {
            setConfigsOpenedViaPredictButton(false)
          }
        }}
        closable
        width={800}
        footer={
          <Space style={{
            justifyContent: "flex-end"
          }} >
            <StyledButton
              onClick={() => {
                setInverseConfigurationData((prev: any) => {
                  const newState = JSON.parse(JSON.stringify(prev ?? {})) ?? {}
                  return {
                    ...newState,
                    [currentSelectedStage]: JSON.parse(JSON.stringify(defaultInverseConfigData ?? {}))
                  }
                })
              }}
              type='default'
            >
              {t("common.reset")}
            </StyledButton>
            {configsOpenedViaPredictButton ?
              <StyledButton
                loading={newInverseModel.inverseStatus === AsyncStates.LOADING}
                type="primary"
                onClick={() => {
                  if (validationsForSteps()) {
                    setUnsavedChanges(false)
                    generateExp('range')
                    setIsCofigurationModalVisible(false)
                    setConfigsOpenedViaPredictButton(false)
                  }
                }}
                style={{ outline: 'none' }}
              >
                {newInverseModel.inverseStatus === AsyncStates.LOADING ? t('aiEngine.predicting') : t('aiEngine.inverseModel.predictExperiments')}
              </StyledButton> :
              <StyledButton
                onClick={() => {
                  setIsCofigurationModalVisible(false)
                  if (configsOpenedViaPredictButton) {
                    setConfigsOpenedViaPredictButton(false)
                  }
                }}
                type='primary'
              >
                {t("common.continue")}
              </StyledButton>
            }
          </Space>
        }
      >
        <InverseConfigs
          currentSelectedStage={currentSelectedStage}
          fromPredictButton={configsOpenedViaPredictButton}
          inverseConfigurationData={inverseConfigurationData?.[currentSelectedStage] ?? {}}
          setInverseConfigurationData={setInverseConfigurationData}
        />
      </Modal>
    </div>

  )
}

export default PayloadInput
