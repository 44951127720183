import {
  AutoComplete,
  Cascader,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  createIngredientClear,
  createIngredientFromTrialClear,
  createIngredientFromTrialRequest,
  createIngredientRequest,
  getIngredientDetailsSuccess,
  getSuppliersRequest,
  updateIngredientClear,
  updateIngredientRequest,
} from "src/store/actions/inventoryV2";
import { Ingredient } from "../types";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import { InfoCircleFilled } from "@ant-design/icons";
import { convertToPrecision } from "src/utils/decorator";
import { useHistory } from "react-router-dom";
import { displayNamesRequest } from "src/store/actions/displayNames";
import { useValue } from "src/utils/useValue";
import { fetchCategoryRequest } from "src/store/actions/repository";

const { Text } = Typography;

const { OptGroup, Option } = Select;

type IngredientModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: "create" | "edit";
  ingredient?: Ingredient;
  from?: "create-from-trial" | "work-order" | undefined;
  trialData?:
  | {
    formulationData: any;
    trialName: string;
    options: any[];
    ingredients: string[];
  }
  | undefined;
};

export const IngredientModal = ({
  open,
  setOpen,
  mode,
  ingredient,
  from,
  trialData,
}: IngredientModalProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { formatter, parser } = useValue()

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const spanSize = from === "create-from-trial" ? 12 : 8

  const history = useHistory();

  const categories = useSelector((state: StoreState) => state.repository.categories);

  const [form] = Form.useForm();

  const { user_role: userRole, currency } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );

  const createIngredientResponse = useSelector(
    (state: StoreState) => state.inventoryV2.createIngredient
  );

  const getSuppliersResponse = useSelector(
    (state: StoreState) => state.inventoryV2.getSuppliers
  );

  const createIngredientFromTrialStatus = useSelector(
    (state: StoreState) =>
      state.inventoryV2.createIngredientFromTrial
        .createIngredientFromTrialStatus
  );

  const updateIngredientResponse = useSelector(
    (state: StoreState) => state.inventoryV2.updateIngredient
  );

  const unitsList = useSelector(
    (state: StoreState) => state.conversion.unitList
  );
  const costingUnitList = useMemo(
    () =>
      unitsList.filter(
        (res: any) =>
          ["weight"].includes(res.category.toLowerCase()) &&
          !["cP", "TgC", "phr"].includes(res.name)
      ),
    [unitsList]
  );

  const [costing, setCosting] = useState<any>({
    quantity: null,
    currency: currency?.currency_code,
    amount: null,
    unit: null,
    cost_added_on: null
  });

  useEffect(() => {
    if (open) {
      setCosting((prev: any) => {
        const data = !!Object.keys(ingredient?.costing || {}).length ? ingredient?.costing : {
          quantity: null,
          currency: currency?.currency_code,
          amount: null,
          unit: null,
          cost_added_on: null
        }
        return { ...data }
      })
      if (mode === "create") {
        // form.resetFields();
        form.setFieldsValue({
          ...(from === "create-from-trial" && {
            trial_data: trialData?.options.map((option) => [option.key]),
            name: trialData?.trialName,
          }),
        });
      } else {
        setSelectedCategory(ingredient?.category ?? null)
        form.setFieldsValue(ingredient);
      }
    }
  }, [ingredient, from, mode, trialData?.trialName, form, open, trialData?.options, currency?.currency_code]);



  const { suppliers } = useSelector(
    (state: StoreState) => state.inventoryV2.getSuppliers
  );


  const filterSubCategories = useMemo(() => {
    return categories.data.find((res: any) => res.category_id === selectedCategory)?.sub_category ?? []
  }, [categories.data, selectedCategory]);

  const onFinish = (values: any) => {
    const nonNullKeys = Object.entries(costing || {}).filter(([key, value]) => {
      return (!["cost_added_on", "currency"].includes(key) && (value !== null && value !== ""))
    }).map(([key, value]) => key);

    const requiredCostingKeys = Object.entries(costing || {}).filter(([key, value]) => {
      return (!["cost_added_on", "currency"].includes(key))
    }).map(([key, value]) => key)

    if (!!nonNullKeys.length && (nonNullKeys.length !== requiredCostingKeys.length)) {
      message.error(t("costing.fillAllDetails"))
      return
    }

    if (costing.amount === 0 || costing.quantity === 0) {
      message.error(t("costing.amountOrQuantityCannotZero"))
      return
    }

    if (from === "create-from-trial") {
      const selectedData = values?.trial_data;
      const selectedParameterKeys = [
        ...new Set(
          selectedData?.map((parameterKey: string) => parameterKey[0])
        ),
      ];
      if (!selectedParameterKeys.includes("ingredients")) {
        return message.error("Please Select at least One Ingredient");
      } else {
        const updatedIdentifierList = selectedData?.reduce(
          (acc: any, curr: string) => {
            const parameter = curr[0];
            if (curr.length === 1) {
              if (parameter === "ingredients") {
                acc = {
                  ...acc,
                  [parameter]: Object.keys(
                    trialData?.formulationData?.[parameter] ?? {}
                  ),
                };
              } else if (
                Array.isArray(trialData?.formulationData?.[parameter])
              ) {
                acc = {
                  ...acc,
                  [parameter]: Object.keys(
                    trialData?.formulationData?.[parameter]?.[0]?.[parameter] ??
                    {}
                  ),
                };
              }
            } else {
              acc = {
                ...acc,
                [parameter]: [...(acc?.[parameter] ?? []), curr[1]],
              };
            }
            return acc;
          },
          {}
        );

        const payload = {
          ...values,
          ...updatedIdentifierList,
          formulation_id: trialData?.formulationData?.id_set?.formulation_id,
          category: values.category,
          sub_category: values.sub_category,
          costing: {
            ...costing,
            amount: convertToPrecision(costing?.amount, 2),
            quantity: convertToPrecision(costing?.quantity, 3),
          },
          unit: [],
        };
        delete payload["trial_data"];
        dispatch(createIngredientFromTrialRequest(payload));
      }
    } else {
      const costingInfo = {
        ...costing,
        amount: convertToPrecision(costing?.amount, 2),
        quantity: convertToPrecision(costing?.quantity, 3),
      };
      const payload = {
        ...values,
        costing: costingInfo,
      };
      // console.log("Received values:", payload);
      if (mode === "create") {
        dispatch(createIngredientRequest({ ...payload, unit: [] }));
      } else {
        dispatch(
          updateIngredientRequest({
            ...payload,
            unit: [],
            inventory_id: ingredient?.inventory_id,
          })
        );
      }
    }
    // Handle form submission logic here
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (getSuppliersResponse.status === AsyncStates.INITIAL) {
      dispatch(getSuppliersRequest());
    }
  }, [getSuppliersResponse.status, dispatch]);

  useEffect(() => {
    if (updateIngredientResponse.status === AsyncStates.SUCCESS) {
      message.success(t("inventory.Ingredientupdated"));
      dispatch(
        getIngredientDetailsSuccess({
          ...ingredient,
          ...updateIngredientResponse.data,
        })
      );
      dispatch(updateIngredientClear());
      dispatch(displayNamesRequest({ backgroundFetch: true }));
      setCosting({
        quantity: null,
        currency: currency?.currency_code,
        amount: null,
        unit: null,
        cost_added_on: null
      })
      setOpen(false)
    }
  }, [currency?.currency_code, dispatch, ingredient, setOpen, updateIngredientResponse.data, updateIngredientResponse.error, updateIngredientResponse.status, t]);

  useEffect(() => {
    if (createIngredientFromTrialStatus === AsyncStates.SUCCESS) {
      dispatch(createIngredientFromTrialClear());
      form.resetFields();
      setCosting({
        quantity: null,
        currency: currency?.currency_code,
        amount: null,
        unit: null,
        cost_added_on: null
      })
      setOpen(false);
    }
  }, [createIngredientFromTrialStatus, dispatch, form, setOpen, from, currency?.currency_code]);

  useEffect(() => {
    if (createIngredientResponse.status === AsyncStates.SUCCESS) {
      message.success(t("inventory.Ingredientcreated"));
      const newInventoryId = createIngredientResponse.data.inventory_id;
      dispatch(
        getIngredientDetailsSuccess({
          ...ingredient,
          ...createIngredientResponse.data,
          properties: [],
        })
      );
      dispatch(displayNamesRequest({ backgroundFetch: true }));
      dispatch(createIngredientClear());
      form.resetFields();
      setOpen(false);
      setCosting({
        quantity: null,
        currency: currency?.currency_code,
        amount: null,
        unit: null,
        cost_added_on: null
      })
      if (from !== "work-order") {
        history.push(`/inventory/ingredients/${newInventoryId}`);
      }
    }
  }, [createIngredientResponse.status, createIngredientResponse.data, dispatch, history, ingredient, from, form, setOpen, currency?.currency_code, t]);

  const selectAfter = (
    <Select
      showSearch
      value={costing?.unit}
      onChange={(value) => {
        setCosting((prev: any) => ({ ...prev, unit: value }));
      }}
      placeholder={t("common.selectUnit")}
      style={{ minWidth: "6rem" }}
    >
      {Array.from(
        new Set(costingUnitList.map((res: any) => res.category.toLowerCase()))
      ).map((category: any) => (
        <OptGroup
          label={
            String(category).charAt(0).toLocaleUpperCase() +
            String(category).slice(1)
          }
        >
          {costingUnitList
            .filter((res: any) => res.category === category)
            .map((res: any, index: any) => (
              <Option value={res.name} key={res.name + index}>
                {res.name}
              </Option>
            ))}
        </OptGroup>
      ))}
    </Select>
  );

  const supplierOptions = useMemo(() => {
    return [...suppliers, {
      name: "Self",
      supplier_id: "self"
    }]?.filter((res: any) => {
      return !!res.name?.trim()
    }).map((res: any) => ({
      label: res.name,
      value: res.name,
      key: res?.supplier_id
    })) ?? []
  }, [suppliers])


  useEffect(() => {
    if (open) {
      dispatch(fetchCategoryRequest({ type: "INGREDIENT" }))
    }
  }, [dispatch, open])

  return (
    <Modal
      title={`${mode === "create" ? t("common.create") : t("common.update")} ${t("inventory.Ingredient")}`}
      footer={null}
      width={800}
      open={open}
      onCancel={() => {
        setOpen(false);
        setCosting({
          quantity: null,
          currency: currency?.currency_code,
          amount: null,
          unit: null,
          cost_added_on: null,
        });
        form.resetFields();
      }}
      maskClosable={false}
    >
      <Form
        key={ingredient?.inventory_id}
        form={form}
        size="small"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={spanSize}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {from === "create-from-trial" ? (
            <Col span={spanSize}>
              <Form.Item
                label={"Select Data"}
                name="trial_data"
                valuePropName="value"
                rules={[{ required: true, message: t("common.requiredField") }]}
              >
                <Cascader
                  style={{ width: "100%" }}
                  options={trialData?.options}
                  multiple
                  showCheckedStrategy={"SHOW_PARENT"}
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col span={spanSize}>
            <Form.Item
              label={t("common.category")}
              rules={[{ required: true, transform: (value) => value?.trim() }]}
              name="category"
              required
            >
              <Select
                showSearch
                placeholder={t("common.selectCategory")}
                filterOption={(inputValue, option: any) => {
                  return option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
                }}
                onChange={(value) => {
                  setSelectedCategory(value)
                  form.setFieldValue("sub_category", undefined)
                }}
                options={categories.data.map((category: any) => ({
                  label: category.name,
                  value: category.category_id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={spanSize}>
            <Form.Item
              label={t("inventory.subCategory")}
              rules={[{ required: false, transform: (value) => value?.trim() }]}
              name="sub_category"
            >
              <Select
                showSearch
                placeholder={`${t("common.select")} ${t(
                  "inventory.subCategory"
                )}`}
                filterOption={(inputValue, option: any) => {
                  return option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
                }}
                options={filterSubCategories.map((category: any) => ({
                  label: category,
                  value: category,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label={t("inventory.lot_no")} name="lot_no">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("inventory.BrandName")} name="brand_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("common.Supplier")} name="supplier">
              <AutoComplete
                showSearch
                placeholder={"Select Supplier"}
                filterOption={true}
                options={supplierOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Col span={8}>
            <Form.Item label={t("common.family")} name="family_id">
              <Select
                disabled={mode === 'edit'}
                showSearch
                placeholder={"Select Family"}
                filterOption={(inputValue, options: any) =>
                  getDropdownFilteredValue(inputValue, options)
                }
                options={familyOptions}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item label={t("common.grade")} name="grade">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("common.useTemplate")} name="from_template">
              <Switch disabled={mode === 'edit' || from === 'create-from-trial' || from === 'work-order'} />
            </Form.Item>
          </Col>

        </Row>

        <Form.Item
          label={t("inventory.costing")}
          rules={[{ required: false, transform: (value) => value?.trim() }]}
          name="costing"
        >
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Input.Group compact>
              <InputNumber
                parser={parser}
                formatter={formatter}
                addonBefore={
                  <div>
                    <Tooltip
                      title={
                        costing ? costing.currency : currency?.currency_name
                      }
                    >
                      <Text strong>
                        {costing?.currency ?? currency?.currency_code}
                      </Text>
                    </Tooltip>
                    {"  "}
                    {userRole === "admin" && (
                      <Tooltip
                        title={
                          currency?.is_default_value_updated
                            ? t("common.currency.updateCurrencyNote")
                            : t("common.currency.updateCurrencyFromProfile")
                        }
                      >
                        <InfoCircleFilled />
                      </Tooltip>
                    )}
                  </div>
                }
                placeholder={t("common.amount")}
                min={0}
                name={"amount"}
                addonAfter={t("common.per")}
                value={costing?.amount}
                style={{ width: "55%" }}
                onChange={(e) => {
                  setCosting((prev: any) => ({ ...prev, amount: e }));
                }}
              />
              <InputNumber
                min={0}
                parser={parser}
                formatter={formatter}
                placeholder={t("common.quantity")}
                name="quantity"
                onChange={(e) => {
                  setCosting((prev: any) => ({ ...prev, quantity: e }));
                }}
                style={{ width: "45%" }}
                value={costing?.quantity}
                addonAfter={selectAfter}
              />
              <div>
                <label>{t("inventory.CostAddedOn")}</label>
                <Input
                  type="date"
                  size="small"
                  name="cost_added_on"
                  value={costing?.cost_added_on}
                  onChange={(e) => {
                    setCosting((prev: any) => ({
                      ...prev,
                      cost_added_on: e.target.value,
                    }));
                  }}
                  placeholder="Select Date"
                />
              </div>
            </Input.Group>
            <Tooltip title={t("costing.clearCosting")}>
              <StyledButton
                onClick={() =>
                  setCosting({
                    quantity: null,
                    currency: currency?.currency_code,
                    amount: null,
                    unit: null,
                    cost_added_on: null,
                  })
                }
              >
                {t("common.clear")}
              </StyledButton>
            </Tooltip>
          </Space>
        </Form.Item>

        {/* <Row gutter={16}>
          <Form.Item>
            <Checkbox onChange={() => setIsFromTemplateChecked(prev => !prev)}>From Templates</Checkbox>
          </Form.Item>

          {
            isFromTemplateChecked && (
              <Form.Item name={"from_template"} label={"Templates"}
                rules={[{ required: true, message: "Please select a template" }]}
              >
                <Select
                  showSearch
                  placeholder={"Select Template"}
                  filterOption={(inputValue, option: any) => {
                    return option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
                  }}
                  options={[]}
                />
              </Form.Item>
            )
          }
        </Row> */}

        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
          <StyledButton
            type="primary"
            htmlType="submit"
            loading={
              createIngredientResponse.status === AsyncStates.LOADING ||
              updateIngredientResponse.status === AsyncStates.LOADING ||
              createIngredientFromTrialStatus === AsyncStates.LOADING
            }
            style={{
              float: "right",
            }}
          >
            {t("common.submit")}
          </StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};
