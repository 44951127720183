import {
  Popover,
  Cascader,
  Typography,
  Space,
  Select,
  Input,
  InputNumber,
  message,
  Spin,
} from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { getDropdownFilteredValue } from "src/utils/decorator";
import { FilterOutlined, LoadingOutlined } from "@ant-design/icons"
import { useValue } from "src/utils/useValue";
import { AsyncStates } from "src/constants";

const { Option } = Select;

const FilterButton = ({
  selectedProjects,
  currentInnerIndex,
  setCurrentInnerIndex,
  currentOuterIndex,
  parameterId,
  appliedFiltersIndices,
  setAppliedFiltersIndices,
  badgeColors,
  parameterCascaderData,
  parameterText,
  clearFilters,
  filters,
  setFilters,
  setFormState,
  form,
  setInnerSelector,
  setOuterSelector
}: any) => {
  const [t] = useTranslate();
  const { formatter, parser } = useValue()
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  );

  const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);
  const [mainParameter, setMainParameter] = useState(parameterId === 'properties' ? [] : [`${parameterId}`]);
  const [mainOperator, setMainOperator] = useState<string | null>(null);
  const [mainValue, setMainValue] = useState<
    string | null | number | boolean | any[]
  >(null);
  const [mainMax, setMainMax] = useState<string | null | number>(null);

  const workOrdersList = useSelector(
    (state: StoreState) => state.workOrders.data,
  );
  const teams = useSelector(
    (state: StoreState) => state.teams.data,
  );
  const ingredientAndPropertyCategoriesStatus = useSelector((state: StoreState) => state.repository.allCategories.status);

  const properties = useSelector(
    (state: StoreState) => state.displayNames.data?.properties,
  );

  const getPropertyParams = () => {
    let propertyParams: any[] = [];
    mainParameter.forEach((p) => {
      if (p.length === 1) {
        propertyParams.push(...Object.keys(properties));
      } else if (p.length === 2) {
        propertyParams.push(...getProperties(p[1]));
      } else {
        propertyParams.push(p[2]);
      }
    });
    return propertyParams;
  };

  const getProperties = (category: string) => {
    return Object.entries(properties || {}).filter(
      (property: any) => property[1].category === category
    ).map((p) => p[0]);
  };

  useEffect(() => {
    if (Object.keys(filters ?? {}).length < 1) {
      setMainParameter(parameterId === 'properties' ? [] : [`${parameterId}`]);
      setMainMax(null);
      setMainValue(null);
      setMainOperator(null);
    }

    if (form) {
      const query = filters || {}
      if (Object.keys(query).length === 0) {
        form.resetFields()
      }
      if (Object.keys(query).length > 0) {
        setInnerSelector(query?.data?.map((res: any) => res?.selector ?? "and"))
        setOuterSelector(query?.selector)
        form.setFieldValue(
          "outer_conditions",
          query?.data?.map((res: any) => ({
            inner_conditions: res?.data?.map((key: any) => {
              return ({
                ...key,
                // ...(key?.parameter_type === "stage" && ({ 
                // val: [...new Set(workOrdersList.flatMap((wo: any) => key.val.map((stage: any) => wo?.stages?.findIndex((res: any) => stage === res?.identifier))).filter((id: any) => id !== -1))] 
                // })),
                parameter: [
                  "material",
                  "application",
                  "grade",
                  "order_type",
                  "work_order_id",
                  "stage",
                  "user_id",
                  "lab_locations"
                ].includes(key?.parameter_type)
                  ? [key?.parameter_type]
                  : [key?.parameter_type, key?.category, key?.parameter],
              })
            }),
          }))
        )
      }

      setFormState({
        outer_conditions: query?.data?.map((res: any) => ({
          inner_conditions: res?.data?.map((key: any) => ({
            ...key,
            // ...(key?.parameter === "stage" && ({
            // , val: [...new Set(workOrdersList.flatMap((wo: any) => key.val.map((stage: any) => wo?.stages?.findIndex((res: any) => stage === res?.identifier))).filter((id: any) => id !== -1))] 
            // })),
            parameter: [
              "material",
              "application",
              "grade",
              "order_type",
              "work_order_id",
              "stage",
              "user_id",
              "lab_locations"
            ].includes(key?.parameter_type)
              ? [key?.parameter_type]
              : [key?.parameter_type, key?.category, key?.parameter],
          })),
        })),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function filter(inputValue: string, path: any) {
    return path.some((option: any) =>
      typeof option?.label === "string"
        ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        : option.label?.props?.children?.props?.children?.[1]
          ?.toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    );
  }

  const onChangeOperator = (value: string) => {
    setMainOperator(value as string);
    setMainMax(null);
    setMainValue(null);
  };

  const getOpertors = useCallback(() => {
    const type = mainParameter[0];
    const parameter = mainParameter[2];
    if (
      ["material", "application", "grade", "order_type"].includes(type) ||
      (["characterization_method_parameters", "characterizations"].includes(
        type,
      ) &&
        !!displayNames?.characterizations?.[parameter]?.options?.length)
    ) {
      return (
        <Select
          onChange={(value) => onChangeOperator(value)}
          style={{ width: 300 }}
          placeholder={t("formulations.placeholder.operator")}
          value={mainOperator}
        // disabled={mainParsameter.length<3}
        >
          <Select.Option value="eq">{"="}</Select.Option>
        </Select>
      );
    } else if (["work_order_id", "stage", "lab_locations"].includes(type)) {
      return (
        <Select
          onChange={(value) => onChangeOperator(value)}
          style={{ width: 300 }}
          placeholder={t("formulations.placeholder.operator")}
          value={mainOperator}
          defaultValue={"in"}
        // disabled={mainParameter.length<3}
        >
          <Select.Option value="in">{t("common.in")}</Select.Option>
        </Select>
      );
    } else if (["user_id"].includes(type)) {
      return (
        <Select
          onChange={(value) => onChangeOperator(value)}
          style={{ width: 300 }}
          placeholder={t("formulations.placeholder.operator")}
          value={mainOperator}
          defaultValue={"in"}
        // disabled={mainParameter.length<3}
        >
          <Select.Option value="in">{t("common.createdBy")}</Select.Option>
        </Select>
      );
    } else if (
      ["characterization_method", "processing_profile"].includes(type)
    ) {
      return (
        <Select
          onChange={(value) => onChangeOperator(value)}
          style={{ width: 300 }}
          placeholder={t("formulations.placeholder.operator")}
          value={mainOperator}
        // disabled={mainParameter.length<3}
        >
          <Select.Option value="exists">{t("common.exists")}</Select.Option>
        </Select>
      );
    } else
      return (
        <Select
          onChange={(value) => onChangeOperator(value)}
          style={{ width: 300 }}
          placeholder={t("formulations.placeholder.operator")}
          value={mainOperator}
          disabled={parameterId === 'properties' ? mainParameter.length < 1 : mainParameter.length < 3}
        >
          <Select.Option value="eq">{"="}</Select.Option>
          <Select.Option value="lt">{"<"}</Select.Option>
          <Select.Option value="gt">{">"}</Select.Option>
          <Select.Option value="range">{t("common.range")}</Select.Option>
          <Select.Option value="exists">{t("common.exists")}</Select.Option>
        </Select>
      );
  }, [displayNames?.characterizations, mainOperator, mainParameter, t, parameterId])

  const getDropdownOptions = useCallback((type: string, parameter: string = "") => {
    if (type === "work_order_id") {
      return workOrdersList
        ?.filter((res: any) => selectedProjects.includes(res?.project_id))
        .map((res: any, index: number) => ({
          label: res?.work_order_name,
          value: res?.work_order_id,
          key: index,
        }));
    } else if (type === "stage") {
      return [
        ...new Set(
          workOrdersList.flatMap((wo: any) =>
            wo.stages?.map((res: any, index: number) => index),
          ),
        ),
      ].map((res: any, index: number) => ({
        value: index,
        label: `Stage ${index + 1}`,
        key: index,
      }));
    } else if (type === "characterizations") {
      return displayNames?.characterizations?.[parameter]?.options?.map(
        (value: any, index: number) => ({ label: value, value, key: index }),
      );
    } else if (["application", "grade", "order_type"]?.includes(type)) {
      return displayNames?.[type]?.map((res: any, index: number) => ({
        label: res,
        value: res,
        key: index,
      }));
    } else if (type === "user_id") {
      // filter(({user_id}:any)=>!filters?.data?.flatMap((filterData:any)=>filterData?.filter(({parameter}:any)=>parameter==="user_id")?.flatMap(({val}:any)=>val))?.includes(user_id))
      // .filters.data.flatMap(({data})=>data).filter(({parameter})=>parameter==="user_id").flatMap(data=>data.val)
      return teams?.filter(({ user_id }: any) =>
        !filters?.data?.flatMap(({ data }: any) => data)
          ?.filter((data: any) => data?.parameter === "user_id")
          ?.flatMap((data: any) => data?.val)?.includes(user_id))

        ?.map(({ user_id, user_name }: any, index: number) => ({
          label: user_name,
          value: user_id,
          key: index,
        }))
    } else if (type === "lab_locations") {
      return labsLocationList.map((location: any, index: number) => ({
        label: location?.name,
        value: location.lab_location_id,
        key: location.lab_location_id,
      }),
      );
    } else {
      return Object.entries(displayNames?.[type] || {})?.map(
        ([key, value]: any, index: number) => ({
          label: value?.name,
          value: key,
          key: index,
        }),
      );
    }
  }, [displayNames, filters.data, selectedProjects, teams, workOrdersList, labsLocationList])

  const getComponentFromOpertors = useCallback(() => {
    const type = mainOperator;
    const parameterType = mainParameter[0];
    const parameter = mainParameter[2];
    if (type === "range") {
      return (
        <Space direction="horizontal" size={"middle"}>
          <InputNumber
            formatter={formatter}
            parser={parser}
            style={{ width: 130 }}
            placeholder={t("common.minimum")}
            onChange={(e) => {
              const value = Number(e);
              if (value && mainMax && value >= Number(mainMax)) {
                message.warning(t("aiEngine.minShouldBeLessThanMax"));
                setMainValue(null);
              } else {
                setMainValue(value);
              }
            }}
            value={mainValue === null || mainValue === '' || Number.isNaN(Number(mainValue)) ? "" : Number(mainValue)}
            disabled={mainOperator === '' || mainOperator === null}
          />
          <Typography.Title level={5} style={{ width: 40 }}>{"~"}</Typography.Title>
          <InputNumber
            formatter={formatter}
            parser={parser}
            style={{ width: 130 }}
            placeholder={t("formulations.placeholder.maximum")}
            onChange={(e) => {
              const value = Number(e);
              if (value && value <= Number(mainValue)) {
                message.warning(t("aiEngine.maxShouldBeGreaterThanMin"));
                setMainMax(null);
              } else {
                setMainMax(value);
              }
            }}
            value={mainMax === null || mainMax === '' || Number.isNaN(Number(mainMax)) ? "" : Number(mainMax)}
            disabled={mainOperator === '' || mainOperator === null}
          />
        </Space>
      );
    } else if (type === "exists") {
      return (
        <Select
          style={{ width: 300 }}
          onChange={(value) => {
            setMainValue(value);
          }}
          value={mainValue}
          placeholder={t("formulations.placeholder.value")}
          disabled={mainOperator === '' || mainOperator === null}
        >
          <Option value={true}>{t("common.true")}</Option>
          <Option value={false}>{t("common.false")}</Option>
        </Select>
      );
    } else if (["gt", "lt"].includes(type as string)) {
      return (
        <InputNumber
          formatter={formatter}
          parser={parser}
          style={{ width: 300 }}
          placeholder={t("formulations.placeholder.value")}
          onChange={(value) => {
            setMainValue(value);
          }}
          value={mainValue ? Number(mainValue) : ""}
          disabled={mainOperator === '' || mainOperator === null}
        />
      );
    } else {
      if (
        ["characterization_method_parameters", "characterizations"].includes(
          parameterType,
        ) &&
        !!displayNames?.characterizations?.[parameter]?.options?.length
      ) {
        return (
          <Select
            showSearch
            filterOption={(inputValue: string, options: any) =>
              getDropdownFilteredValue(inputValue, options)
            }
            onChange={(value) => {
              setMainValue(value);
            }}
            style={{ width: 300 }}
            options={getDropdownOptions(parameterType, parameter)}
            placeholder={t("formulations.placeholder.value")}
            value={mainValue}
            disabled={mainOperator === '' || mainOperator === null}
          />
        );
      } else {
        return (
          <Input
            style={{ width: 300 }}
            onChange={(e) => {
              setMainValue(e.target.value);
            }}
            placeholder={t("formulations.placeholder.value")}
            value={mainValue ? String(mainValue) : ""}
            disabled={mainOperator === '' || mainOperator === null}
          />
        );
      }
    }
  }, [displayNames?.characterizations, getDropdownOptions, mainMax, mainOperator, mainParameter, mainValue, t, formatter, parser])

  return (
    <Popover
      style={{ zIndex: 5, width: 400, height: 300 }}
      content={
        <Space
          style={{
            height: "max-content",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
          size={"middle"}
        >
          {parameterCascaderData !== undefined && (
            <Cascader
              style={{ width: 300 }}
              // dropdownStyle={{ height: 250, width: 300 }}
              showSearch={{
                filter,
              }}
              multiple={parameterId === 'properties'}
              allowClear={false}
              placeholder={parameterText}
              disabled={parameterCascaderData === undefined}
              onChange={(e: any) => {
                setMainParameter(e);
                setMainMax(null);
              }}
              options={
                [{
                  value: parameterId,
                  label: parameterText,
                  children: parameterCascaderData,
                  // disabled: true
                }]
              }
              value={mainParameter}
              dropdownRender={(menu: any) => {
                return (
                  <Spin spinning={ingredientAndPropertyCategoriesStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                    {menu}
                  </Spin>
                )
              }}
            />
          )}

          {getOpertors()}

          {[
            "ingredients",
            "processing",
            "characterizations",
            "properties",
            "characterization_method",
            "characterization_method_parameters",
            "characterization_method_properties",
            "processing_profile",
          ].includes(parameterId === 'properties' ? 'properties' : mainParameter[0]) ? (
            getComponentFromOpertors()
          ) : ["work_order_id", "stage", "user_id", "lab_locations"].includes(mainParameter[0]) ? (
            <Select
              showSearch
              filterOption={(inputValue: string, options: any) =>
                getDropdownFilteredValue(inputValue, options)
              }
              style={{ width: 300 }}
              mode="multiple"
              options={getDropdownOptions(parameterId, mainParameter[2] || "")}
              value={mainValue === null ? [] : mainValue}
              placeholder={mainParameter[0] === 'user_id' ? t("common.users") : t("formulations.placeholder.value")}
              placement="topLeft"
              listHeight={200}
              onChange={(value) => {
                setMainValue(value);
              }}
              disabled={mainOperator === '' || mainOperator === null}
            />
          ) : (
            <Select
              showSearch
              filterOption={(inputValue: string, options: any) =>
                getDropdownFilteredValue(inputValue, options)
              }
              style={{ width: 300 }}
              options={getDropdownOptions(parameterId, mainParameter[2] || "")}
              value={mainValue}
              placeholder={t("formulations.placeholder.value")}
              onChange={(value) => {
                setMainValue(value);
              }}
              disabled={mainOperator === '' || mainOperator === null}
            />
          )}

          <Space>
            <StyledButton
              size="middle"
              type="text"
              onClick={() => {
                setMainParameter(parameterId === 'properties' ? [] : [`${parameterId}`]);
                setMainMax(null);
                setMainValue(null);
                setMainOperator(null);
              }}
              disabled={
                mainParameter.length === 1 &&
                mainOperator === null &&
                mainValue === null
              }
            >
              {t("common.clear")}
            </StyledButton>

            <StyledButton
              size="middle"
              type="primary"
              onClick={() => {

                setFilters((prevState: any) => {
                  let { data } = prevState;
                  let innerData;

                  data && data[currentOuterIndex]
                    ? (innerData = data[currentOuterIndex].data)
                    : (innerData = []);

                  innerData[currentInnerIndex] = {
                    val: mainValue,
                    max: mainMax,
                    parameter: parameterId === 'properties' ? getPropertyParams() : mainParameter[2] || mainParameter[0],
                    parameter_type: parameterId === 'properties' ? 'properties' : mainParameter[0],
                    category: parameterId === 'properties' ? mainParameter.map(item => item[1]) : mainParameter[1] || mainParameter[0],
                    operator: mainOperator,
                    selector: "and",
                  };

                  if (
                    !(
                      mainParameter.length < 1 &&
                      mainOperator === null &&
                      mainValue === null
                    )
                  ) {
                    if (data === undefined) data = [];
                    data[currentOuterIndex] = {
                      data: innerData,
                      selector: "and",
                    };
                  }
                  return { data, selector: "and" };
                });
                setPopoverVisible(false);
                setMainParameter(parameterId === 'properties' ? [] : [`${parameterId}`]);
                setMainMax(null);
                setMainValue(null);
                setMainOperator(null);

                if (appliedFiltersIndices[`${parameterId}`] === -1) {
                  setAppliedFiltersIndices((prevState: any) => ({
                    ...prevState,
                    [`${parameterId}`]: currentInnerIndex,
                  }));
                }
                setCurrentInnerIndex((prev: number) => prev + 1);
              }}

              disabled={
                mainOperator === null ||
                mainValue === null ||
                (mainOperator === "range" && (mainMax === null || mainMax < mainValue))
              }
            >
              {"Add"}
            </StyledButton>
          </Space>
        </Space>
      }
      trigger="click"
      placement="bottomLeft"
      open={popoverVisible}
      onOpenChange={(visible: boolean) => setPopoverVisible(visible)}
    >
      <StyledButton type="primary" ghost style={appliedFiltersIndices[`${parameterId}`] !== -1 ? { backgroundColor: badgeColors[`${parameterId}`], color: '#fff', border: "none" } : {}}>
        <FilterOutlined />
        {parameterText}
      </StyledButton>
    </Popover>
  );
};

export default FilterButton;